
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "CormorantSC";
    src: local("CormorantSC"),
     url("/Users/connorspackman/Documents/bobbie_website/client/src/assets/fonts/CormorantSC-Regular.ttf") format("truetype");
    /* font-weight: bold; */
    }

@font-face {
    font-family: "PlayfairDisplay";
    src: local("PlayfairDisplay"),
     url("/Users/connorspackman/Documents/bobbie_website/client/src/assets/fonts/PlayFairFont/PlayfairDisplay-Regular.ttf") format("truetype");
        /* font-weight: bold; */
    }